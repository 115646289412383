import React from "react";
import { Container, Divider, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  title: {
    textAlign: "center",
  },
  divider: {
    marginBottom: theme.spacing(4),
  },

  owner: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  ownerImage: {
    textAlign: "center",
    margin: "1em auto",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
    "& img": {
      borderRadius: "50%",
    },
  },
  ownerContent: {
    flex: 2,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "3em",
    },
  },
}));

const About = ({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
  },
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title="About" />
      <Container maxWidth="md" className={classes.container}>
        <section>
          <Typography variant="h4" gutterBottom className={classes.title}>
            {t("about.founder-title", { siteTitle })}
          </Typography>
          <Divider className={classes.divider} />

          <div className={classes.owner}>
            <div className={classes.ownerImage}>
              <StaticImage
                src="../images/rubtsova2.jpg"
                width={1200}
                quality={90}
                aspectRatio={1}
                transformOptions={{
                  cropFocus: "center",
                }}
                alt="Rubtsova"
              />
            </div>

            <div className={classes.ownerContent}>
              <Typography variant="h5" gutterBottom>
                {t("owner")}, <i>{t("owner-title")}</i>
              </Typography>
              <Typography
                component="div"
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: t("about.owner-description"),
                }}
              ></Typography>
            </div>
          </div>
        </section>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query SiteTitleAboutQuery($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default About;
